@import "lesshat";
@import "../bower_components/bootstrap/less/variables";
@import "../bower_components/bootstrap/less/mixins";
@import "../bower_components/bootstrap/less/modals";
@import "../bower_components/bootstrap/less/close";
@import "variables";
@import "mixins";

* {
    font-family: consolas, monospace
}

.x-form-item, .x-form-field {
    font-family: consolas, monospace
}

body {
    font: 14px consolas, monospace, Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.main-header {
    color: @webfiddle-blue;
    background-color: #f5f5f5;
}

.webfiddle-light-blue {
    color: @webfiddle-light-blue;
}

.main-header_heading {
    top: -7px;
    position: relative;
    padding: 10px;
    padding-top: 50px;
    font-family: consolas, Courier, monospace;
    display: inline;
}

.main-content {
    min-height: 40px;
    width: 100%;
    height: 300px;
}

a {
    color: @webfiddle-blue;
    &:hover {
        color: @webfiddle-light-blue;
    }
}

.code-editor {
    height: 100%;
}

.CodeMirror {
    height: 100%;
}

.CodeMirror-scroll {
    padding: 0;
}

.CodeMirror-gutters {
    height: 100% !important;
}

.header-btn {
    display: inline-block;
    vertical-align: top;
    padding: 18px;
    width: 75px;

    position: relative;
    font-size: 16px;
    text-decoration: none;
    &:hover, &:focus {
        color: white;
        background-color: @webfiddle-blue;
    }
    .fa {
        font-size: 23px;
    }

    .transition(all 0.3s ease-in);

    @media (min-width:650px) {
       width: 85px;
    }
}

.web-frame, .web-iframe {
    width: 100%;
    height: 100%;
    border: 0;
}

.mm-sharing-btns {
    .font-size(1.9);
    a {
        text-decoration: none;
    }
    margin-top: 0;
    margin-bottom: 50px;
}

.mm-share-btn {
    margin-top: 10px;
    font-size: @base-font-size *2;
}

.mm-share-btn--twitter {
    color: @twitter-blue;
}

.mm-share-btn--google-plus {
    color: @google-plus-red;
}

.mm-share-btn--pinterest {
    color: @pinterest-red;
}

.mm-share-btn--linked-in {
    color: @linked-in-blue;
}

.mm-share-btn--reddit {
    color: @reddit-blue;
}

.mm-share-btn--stumbleupon {
    color: @stumble-upon-orange;
}

.mm-share-btn--hackernews {
    color: @ycombinator-orange;
}

.web-iframe-loading {
    height: 100%;
    width: 100%;
    text-align: center;
    display: table;
    .web-bigspinner {
        vertical-align: middle;
        display: table-cell;
    }
}

.web-bigspinner {
    font-size: 30px;
}

.web-extra {
    position: absolute;
}

.webfiddle-share-link {
    width: 80%;
}

.inlet_slider {
    z-index: 9999;
}
